/* ------------------------------------------------------------ *\
	Box
\* ------------------------------------------------------------ */

.boxes {
	min-height: 340px; 
	margin-bottom: 68px; 

	@media (max-width: @responsive-xs){
		max-width: 380px; 
		margin: 0 auto 30px; 
	}


	&.full {
		.boxes__actions {
			display: none; 
		}
	}

	.boxes__body {
		margin-bottom: 27px; 
	}

	.boxes__actions {
		text-align: center; 
	}

	.nav-secondary {
		margin-bottom: 38px; 
	}
}

.box {
	margin-bottom: 23px; 

	.box__container {
		position: relative; 
		overflow: hidden; 
		display: block; 
		height: 240px; 
		background-position: 50% 50%; 
		background-repeat: no-repeat; 
		background-size: cover;
		font-size: 11px; 
		line-height: 1.64; 
		color: #fff;
		text-decoration: none; 

		&:hover {
			.box__inner {
				top: 0; 
				transform: translateY(0);
			}
		}

		&--contain {
			background-size: contain; 
		}
	}

	.box__inner {
		position: relative; 
		top: 100%; 
		transform: translateY(-57px); 
		height: 100%; 
		padding: 13px 13px 20px 13px; 
		background: @color-blue; 
		transition: .4s; 
	}

	.box__head {
		min-height: 45px; 
		margin-bottom: 15px; 
	}

	.box__body {
		overflow-y: auto; 
		max-height: 160px; 
		padding: 21px 0 15px; 
		border-top: 1px solid #fff; 
	}

	.box__title {
		margin: 0 0 1px; 
		font-weight: 400; 
	}

	.box__meta {
		overflow: hidden; 
		font-weight: 300; 
		text-overflow: ellipsis; 
		white-space: nowrap; 
	}

	.box__entry {
		font-family: @font-family-gotham; 
	}

	.box__actions {
		text-align: center; 
	}

	@media (max-width: @responsive-md){
		.btn {
			min-width: 0; 
		}
	}
}