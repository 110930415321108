/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	background: @color-blue-dark;
	color: #fff; 

	.header__container {
		display: flex; 
		justify-content: space-between; 
		align-items: flex-start; 
		padding-bottom: 16px; 

		@media (max-width: @responsive-xs){
			padding-bottom: 20px; 
		}
	}

	.header__inner {
		display: flex; 
		justify-content: space-between; 
	}

	.header__content {
		position: fixed; 
		top: 0; 
		right: 0; 
		bottom: 0; 
		left: 0; 
		z-index: 10; 
		transform: translateY(-130%); 
		padding-top: 100px; 
		background: @color-blue-dark; 
		transition: .5s; 
	}

	.header__content-inner {
		padding: 85px 15px; 
		max-height: calc(~"100vh - 88px"); 
		overflow-y: auto; 
		overflow-x: hidden; 
		margin: 0 -15px; 
		background: @color-blue-dark; 
	}

	.header__contact {
		position: relative; 
		display: block; 
		padding: 0 0 22px 51px; 
		margin-bottom: 26px; 
		font-size: 18px; 
		letter-spacing: .05em; 
		text-decoration: none; 

		&:after {
			content: '';
			position: absolute; 
			bottom: 0; 
			left: 0; 
			width: 100%; 
			height: 1px; 
			background: #fff; 
		}

		&:hover {
			path {
				fill: @color-blue;
			}
		}

		.header__contact-icon {
			position: absolute; 
			top: -3px; 
			left: 0; 
			display: inline-block; 
			vertical-align: middle; 
		}
	}

	.header__popups {
		position: relative; 
	}

	.header__popup {
		position: absolute; 
		top: 0;
		left: 25.7%; 
		z-index: 30;
		width: 100%; 
		max-width: 68.3%; 
		padding: 100px 130px 82px 100px; 
		border-radius: 0 0 20px 20px; 
		background: @color-blue; 
		opacity: 0; 
		visibility: hidden; 
		transition: .3s; 

		@media (max-width: @responsive-sm){
			left: 0; 
			max-width: 100%; 
		}

		@media (max-width: @responsive-xs){
			padding: 40px 15px 60px; 
		}

		&.visible {
			opacity: 1; 
			visibility: visible; 
		}

		.header__popup-actions {
			text-align: right; 
			text-transform: uppercase; 
		}

		.header__popup-btn-close {
			position: absolute;
			bottom: 24px; 
			right: 30px; 
			padding-right: 31px; 
			font-size: 15px; 
			font-weight: 800; 
			letter-spacing: .05em; 
			text-transform: uppercase; 

			&:hover {
				text-decoration: none;
				color: @color-blue-dark;

				&:before,
				&:after {
					background: @color-blue-dark; 
				}
			}
			
			&:before,
			&:after {
				content: '';
				position: absolute; 
				top: 1px; 
				right: 8px; 
				width: 3px; 
				height: 20px; 
				background: #fff; 
				transition: background .3s; 
			}

			&:before {
				transform: rotate(-45deg); 
			}

			&:after {
				transform: rotate(45deg); 
			}
		}
	}
	
	.nav-access {
		@media (max-width: @responsive-xs){
			display: none; 
		}
	}

	.logo {
		position: relative; 
		z-index: 1; 
		margin-top: 44px; 
		transition: z-index .3s; 
	}

	.nav-trigger {
		margin: 28px 0 0 28px; 
	}

	.search {
		max-width: 332px; 
		margin-bottom: 80px; 

		.search__field {
			height: 54px; 
			padding-left: 16px; 
			font-size: 25px; 
		}

		.search__btn {
			right: 19px; 
		}

		@media (max-width: @responsive-xs){
			margin-bottom: 40px; 
		}
	}

	&.active {
		.logo {
			z-index: 20; 
			transition: z-index .3s .3s; 
		}

		.header__content {
			transform: translateY(0); 
		}
	}

}
