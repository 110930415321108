/* ------------------------------------------------------------ *\
	Table Default
\* ------------------------------------------------------------ */

.table-default {
	@media (max-width: @responsive-xs){
		overflow-x: auto; 
	}

	table {
		min-width: 500px; 
		width: 100%; 
		table-layout: fixed;
		font-size: 13px; 
		line-height: 1.3; 
		letter-spacing: .05em; 
		color: #000;
	}

	thead th {
		font-weight: 700; 
		text-transform: uppercase; 
	}
	tbody td {
		&:first-child {
			padding: 5px 10px 4px 0; 
			letter-spacing: 0; 
			color: @color-blue-dark;
		}

		span {
			display: inline-block; 
		}
	}

	th {
		padding: 0 0 10px 8px; 
	}

	td {
		padding: 5px 0 4px 8px; 
		vertical-align: top; 
	}
}
