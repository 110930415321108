/* ------------------------------------------------------------ *\
	Info Box
\* ------------------------------------------------------------ */

.info-box {
	position: relative; 

	&:before,
	&:after {
		content: '';
		position: absolute; 
		left: 1px; 
		right: 1px; 
		z-index: 1; 
		height: 13px; 
		background: @color-blue-dark; 
	}

	&:before {
		top: 1px; 
	}

	&:after {
		bottom: 0; 
	}

	.info__inner {
		position: relative; 
		overflow-y: auto; 
		height: 214px; 
		padding: 14px 21px; 
		border-width: 1px 1px 0 1px;
		border-style: solid; 
		border-color: #4e4e4e;
		background: @color-blue-dark !important; 
		color: #fff;

		&::-webkit-scrollbar { 
			display: none; 
		}
	}

	.info__title {
		letter-spacing: .05em; 
	}

	.info__body {
		margin-bottom: 18px; 
	}
}

/* ------------------------------------------------------------ *\
	Google Maps Info Window
\* ------------------------------------------------------------ */

.info-window { 
	width: 370px !important; 
	height: 213px !important; 
	
	/*  Info Window Arrow  */
	&:after {
		content: ''; 
		position: absolute; 
		top: 100%; 
		left: 50%; 
		z-index: 5; 
		border-width: 16px 16px 0 16px; 
		border-style: solid; 
		border-color: @color-blue-dark transparent transparent transparent; 
		margin-left: -15px;
	}

	& > div > div {
		&:nth-child(1) {
			visibility: hidden; 
			opacity: 1; 
		}

		&:nth-child(2),
		&:nth-child(4) {
			width: 370px !important; 
			height: 213px !important; 
			background-color: transparent !important; 
			box-shadow: none !important;
		}
		
		/*  Triangle  */
		&:nth-child(3){
			display: none; 
		}
	}

	.gm-style-iw {
		top: 0 !important; 
		left: 0 !important; 
		width: 370px !important; 
		height: 230px !important; 
		overflow: hidden !important;

		& > div,
		& > div > div {
			max-width: 100% !important; 
			max-height: 100% !important; 
			width: 100% !important; 
			height: 100% !important; 
			visibility: visible; opacity: 1;
		}

		& > div > div {
			padding: 0px; 
			overflow: hidden !important;
		}

		/*  Close Button  */
		& + div {
			top: 0 !important; 
			left: 0 !important; 
			width: 370px !important; 
			height: 0 !important; 
			opacity: 1 !important; 
			overflow: visible !important;

			&:after {
				content: ''; 
				position: absolute; 
				top: 8px; 
				right: 8px; 
				width: 15px; 
				height: 15px; 
				background: url(../images/ico-close.png) 0 0 no-repeat; 
				background-size: 100% 100%;
			}

			& > img {
				display: none; 
			}
		}
	}
}