/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	background: #fff; 
	padding: 0; 

	@media (max-width: @responsive-xs){
		padding-top: 2px; 
	}

	.section__content {
		padding-top: 25px; 
	}

	.section__aside {
		padding-top: 25px; 

		@media (max-width: @responsive-xs){
			padding: 10px 0; 
		}
	}

	.section__aside-head {
		margin-bottom: 70px; 

		@media (max-width: @responsive-xs){
			margin-bottom: 30px; 
		}
	}

	.section__head {
		margin-bottom: 37px; 

		&--offset-bottom {
			margin-bottom: 62px;
		}

		&--offset-bottom-large {
			margin-bottom: 78px; 	
		}

		@media (max-width: @responsive-xs){
			margin-bottom: 20px; 

			&--offset-bottom {
				margin-bottom: 42px; 
			}
		}
	}

	.section__image {
		margin-bottom: 22px; 
	}

	.section__entry {
		margin-bottom: 53px; 
		line-height: 1.6; 

		p {
			margin-bottom: 25px; 
		}
	}

	&--offset-top {
		padding-top: 100px; 

		@media (max-width: @responsive-xs){
			padding-top: 40px; 
		}
	}

	&--contacts {
		padding: 34px 0 115px; 
		
		@media (max-width: @responsive-sm){
			padding: 30px 0 70px; 
		}

		@media (max-width: @responsive-xs){
			padding: 20px 0 40px; 
		}
	}

	&--about {
		padding-top: 69px; 
	}

	&--default {
		background: rgba(255,255,255, .75); 	
	}

	&--text {
		padding: 60px 0 20px;

		.section__head {
			margin-bottom: 48px; 
		}
	}

	&--sponsorship {
		.section__head {
			padding: 70px 0 60px; 
		}

		@media (max-width: @responsive-sm){
			.section__head {
				padding: 50px 0; 
			}
		}

		@media (max-width: @responsive-xs){
			.section__head {
				padding: 20px 0; 
			}
		}
	}
}
