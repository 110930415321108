/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.articles {
	margin-bottom: 114px; 

	.articles__body {
		margin-bottom: 40px;
	}

	.articles__foot {
		text-align: center; 

		&--align-left {
			text-align: left; 
		}
	}

	.articles__head {
		margin-bottom: 45px; 
	}

	&--offset-bottom-small {
		margin-bottom: 56px; 
	}

	@media (max-width: @responsive-xs){
		margin-bottom: 50px;  

		.articles__body {
			margin-bottom: 20px; 
		}

		.articles__head {
			margin-bottom: 20px; 
		}
	}
}

.article {
	margin-bottom: 67px; 

	@media (max-width: @responsive-xs){
		margin-bottom: 30px; 
	}

	.article__head {
		margin-bottom: 26px; 

		&--offset-bottom {
			margin-bottom: 45px; 
		}
	}

	.article__title {
		text-transform: none; 
		line-height: 1.2; 
		letter-spacing: 0; 
	}

	.article__entry {
		p {
			margin-bottom: 25px; 
			line-height: 1.6; 
		}

		&--offset-right {
			padding-right: 70px; 

			@media (max-width: @responsive-xs){
				padding-right: 0; 
			}
		}
	}

	.article__images {
		padding-top: 17px; 

		.article__image {
			margin-bottom: 35px; 
		}
	}

	.article__image {
		margin-bottom: 15px; 
	}

	& > .article__image {
		margin-bottom: 26px; 
	}

	.article__meta {
		margin-bottom: 12px; 
		font-weight: 700; 
	}

	.article__actions-icon {
		display: inline-block; 
		vertical-align: middle; 
		margin-right: 12px; 
	}

	hr {
		margin: 30px 0; 
	}

	.list-dashes {
		margin-bottom: 20px; 
	}

	&--offset-bottom-small {
		margin-bottom: 41px; 
	}

	&--simple {
		margin-bottom: 57px; 

		.article__image { 
			margin-bottom: 42px; 
		}

		.article__image a { 
			display: inline-block; text-decoration: none; 
		}

		.article__image a:hover { 
			opacity: .8; 
		}
		
		.article__head {

		}

		.article__title { 
			overflow: hidden; 
			max-height: 4.8em; 
			margin-top: 7px; 
			text-overflow: ellipsis; 
		}

		.article__title a { 
			text-decoration: none; 
		}

		.article__meta { 
			font-size: 14px; 
			font-weight: 400; 
			color: @color-blue; 
		}

		@media (max-width: @responsive-xs){
			margin-bottom: 30px; 

			.article__image {
				margin-bottom: 20px;
			}
		}
	}

	&.js-accordion-parent {
		.js-accordion-trigger {
			cursor: pointer; 
		}

		&.opened {
			.title-arrow:after {
				transform: rotate(180deg);
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Article Single
\* ------------------------------------------------------------ */

.article-single {
	padding-bottom: 35px; 

	.article__head {
		margin-bottom: 19px; 
	}

	.article__head-meta {
		font-size: 15px; 
		font-weight: 700; 
		letter-spacing: .05em; 
	}

	.title {
		margin: 0 0 23px; 
		letter-spacing: 0; 
	}

	.article__body {
		margin-bottom: 29px; 
	}

	.article__image {
		margin-bottom: 25px; 
	}

	.article__entry {
		line-height: 1.62; 

		p {
			margin-bottom: 25px; 
		}
	}

	.article__actions {
		display: flex; 
		justify-content: space-between; 
		padding: 23px 0; 
		border-top: 1px solid @color-blue-dark; 
	}

	.article__btn {
		border: 0 none; 
		border-radius: 0; 
		background: transparent; 
		font-size: 15px; 
		font-weight: 700; 
		letter-spacing: .05em; 
		text-transform: uppercase; 
		text-decoration: none; 
	}

	.tags {
		margin-bottom: 34px; 
	}

	.socials-boxes {
		max-width: 674px; 
		margin-bottom: 35px; 
	}
}

/* ------------------------------------------------------------ *\
	Article Simple
\* ------------------------------------------------------------ */

.article-simple {
	margin-bottom: 68px; 

	.article__title {
		position: relative;
		padding-bottom: 12px;
		margin-bottom: 16px;   
		letter-spacing: 0; 
		text-transform: none; 

		&:after {
			content: '';
			position: absolute; 
			bottom: 0; 
			left: 0; 
			width: 100%;
			max-width: 270px; 
			height: 1px; 
			background: @color-blue-dark;  
		}
	}

	.article__image {
		margin: 45px 0; 
	}

	.article__head {
		margin-bottom: 38px; 
	}

	.article__entry {
		line-height: 1.6; 

		p {
			margin-bottom: 25px; 
		}
		
		a[href^="mailto:"],
		a[href^="tel:"] {
			text-decoration: none; 
		}

		h5 {
			margin-bottom: 3px; 
			text-transform: none; 
		}
	}

	&--offset-right {
		padding-right: calc(~"11.11111% + 33px"); 

		@media (max-width: @responsive-md){
			padding-right: 0; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Article Small
\* ------------------------------------------------------------ */

.article-small {
	margin-bottom: 45px; 

	.article__head {
		padding-bottom: 18px; 
		border-bottom: 1px solid @color-blue-dark; 
		margin-bottom: 22px; 
	}

	.article__image {
		margin-bottom: 30px;
	}

	.article__title { 
		overflow: hidden; 
		height: 4.2em; 
		margin-bottom: 2px; 
		color: @color-blue; 
		line-height: 1.4; 
		text-overflow: ellipsis; 

		a {
			display: inline-block; 
			text-decoration: none; 

			&:hover {
				color: @color-blue-dark;
			}
		}
	}

	.article__meta {
		font-size: 14px; 
		font-weight: 400;
	}

	.article__entry {
		overflow: hidden; 
		height: 5.6em; 
		margin-bottom: 8px; 
		font-size: 16px; 
		font-weight: 400; 
		letter-spacing: .05em; 
		line-height: 1.4; 
		text-overflow: ellipsis; 
	}

	.article__link {
		font-size: 11px; 
		font-weight: 600; 
		text-transform: uppercase; 
	}

	&--shorter {
		.article__title {
			height: 1.4em; 
		}

		.article__entry {
			height: 2.8em; 
		}
	}

	@media (max-width: @responsive-xs){
		margin-bottom: 30px; 

		.article__head {
			padding-bottom: 10px; 
			margin-bottom: 10px; 
		}

		.article__title {
			height: 2.8em; 
		}

		&--shorter {
			.article__title {
				height: 1.4em; 
			}

			.article__entry {
				height: 2.8em; 
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Article Image Asid
\* ------------------------------------------------------------ */

.article-image-aside {
	margin-bottom: 50px; 

	.article__inner { 
		display: flex; 
		justify-content: space-between; 
		align-items: flex-start; 
		flex-flow: row nowrap; 
		margin: 0 -20px; 
	}

	.article__image {
		width: 44%; 
		padding: 0 20px; 

		a {
			display: inline-block; 

			&:hover {
				opacity: .8; 
			}
		}
	}

	.article__content {
		width: 56%; 
		padding: 5px 20px; 
	}

	.article__title {
		overflow: hidden; 
		height: 4.8em; 
		margin: 13px 0 10px; 
		line-height: 1.2; 
		letter-spacing: normal; 
		text-overflow: ellipsis; 
		text-transform: none; 

		a {
			display: inline-block; 
			text-decoration: none; 
		}
	}

	.article__meta {
		font-size: 14px; 
		color: @color-blue; 
	}

	@media (max-width: @responsive-xs){
		margin-bottom: 30px; 

		.article__inner {
			display: block; 
		}

		.article__image {
			width: 100%; 
			margin-bottom: 10px; 
		}

		.article__content {
			width: 100%; 
		}
	}
}
