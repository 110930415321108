/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	padding: 65px 0 0; 
	color: #fff;
	
	a {
		text-decoration: none; 
	}
	
	a:focus,
	a:hover {
		color: @color-blue;
	}

	.footer__inner {
		padding-bottom: 28px; 
	}

	.footer__contacts {
		margin-bottom: 28px; 
		line-height: 1.64; 
		letter-spacing: .075em; 

		a {
			font-weight: 800; 
			text-transform: uppercase; 
		}
	}

	.footer__title {
		margin: 0 0 22px; 
		line-height: 1.3;
		font-weight: 800; 
		letter-spacing: .1em; 
	}

	.footer__feed {
		display: block; 
		margin-bottom: 32px; 
		line-height: 1.3; 
		letter-spacing: .1em; 
	}

	.footer__bar {
		padding: 15px 0 23px; 
		background: #fff; 
		font-weight: 300; 
		letter-spacing: .05em; 
		color: @color-blue-dark;

		.footer__bar-inner {
			display: flex; 
			justify-content: space-between; 

			@media (max-width: @responsive-xs){
				flex-direction: column;
			}
		}
	}

	.logo {
		margin-bottom: 37px; 
	}
	
	@media (max-width: @responsive-sm){
		div[class^="col"] {
			margin-bottom: 20px; 
		}
	}
}
