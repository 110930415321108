/* ------------------------------------------------------------ *\
	Map
\* ------------------------------------------------------------ */

.map {
	position: relative; 

	.map__container {
		height: 840px; 

		@media (max-width: @responsive-sm){
			height: 600px; 
		}

		@media (max-width: @responsive-xs){
			height: 400px; 
		}
	}
}