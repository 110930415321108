/* ------------------------------------------------------------ *\
	Poster
\* ------------------------------------------------------------ */

.poster {
	position: relative; 
	width: 100%; 
	padding-top: 100%; 

	.poster__container {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		background: #b5b5b5; 
	}

	&--gutter-top {
		margin-top: 50px; 
	}

	&--gutter-bottom {
		margin-bottom: 80px; 
	}

	&--shorter {
		padding-top: 15%; 
	}

	@media (max-width: @responsive-xs){
		&--gutter-top {
			margin-top: 20px; 
		}

		&--gutter-bottom {
			margin-bottom: 30px; 
		}
	}
}
