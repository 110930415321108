/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	display: inline-block; 
	vertical-align: middle;
	max-width: 259px;  
	font-size: 0; 
	line-height: 0; 
	text-decoration: none; 

	&--large {
		max-width: 300px; 
	}
}