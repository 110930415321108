/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	min-width: 320px;
	min-height: 100vh;

	&.disable-scroll {
		position: fixed;
		top: 0;
		left: 0;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}
}

a {
	color: inherit;
	text-decoration: none;
	transition: .3s;

	&:hover,
	&[href^="tel"] {
		text-decoration: underline;
	}
}

img {
	max-width: 100%;
}

hr {
	border-color: @color-blue-dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	text-transform: uppercase;
}

h1 {
	font-size: 55px;
	letter-spacing: .025em;
}

h2 {
	font-size: 40px;
}

h3 {
	font-size: 30px;
	letter-spacing: .05em;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 15px;
}


@media (max-width: @responsive-md) {
	body {
		font-size: 14px;
	}

	h1 {
		font-size: 40px;
	}

	h2 {
		font-size: 30px;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 18px;
	}

	h5 {
		font-size: 14px;
	}

	h6 {
		font-size: 12px;
	}
}
