/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */

/*  Intro Nav Icons  */
.ico-globe {
	width: 43px; 
	height: 43px; 
}

.ico-phone-contacts {
	width: 37px; 
	height: 43px; 
}

.ico-graduate-hat {
	width: 75px; 
	height: 44px; 
}

.ico-newspaper {
	width: 50px; 
	height: 44px; 
}

.ico-globe,
.ico-phone-contacts,
.ico-graduate-hat,
.ico-newspaper {
	path {
		fill: #fff;
		transition: .3s; 
	}

	@media (max-width: @responsive-xs){
		& {
			width: 24px; 
			height: 24px; 
		}
	}
}

/*  Socials Icons  */
.ico-mail-small {
	width: 20px; 
	height: 20px; 
}

.ico-pinterest {
	width: 20px; 
	height: 20px; 
}

.ico-facebook {
	width: 11px; 
	height: 22px; 
}

.ico-twitter {
	width: 21px; 
	height: 18px; 
}

.ico-linkedin {
	width: 20px; 
	height: 20px; 
}

.ico-mail-small,
.ico-pinterest,
.ico-facebook,
.ico-twitter,
.ico-linkedin {
	path {
		fill: @color-blue-dark;
		transition: .3s; 
	}
}

/*  Contacts Icons Circle  */
.ico-phone-circle,
.ico-mail-circle {
	width: 32px; 
	height: 32px; 

	path {
		fill: #fff;
		transition: .3s; 
	}
}

/*  Contacts Icons  */
.ico-phone,
.ico-home,
.ico-mail {
	width: 20px; 
	height: 20px; 

	path {
		fill: @color-blue-dark;
	}
}

/*  Play Icon  */
.ico-play {
	width: 80px; 
	height: 80px; 

	path {
		fill: #fff;
	}
}

/*  PDF Icon  */
.ico-pdf {
	display: inline-block; 
	vertical-align: middle; 
	width: 39px; 
	height: 44px; 

	.ico__sheet {
		fill: #878787;
	}

	.ico__red {
		fill: #e30613;
	}
}