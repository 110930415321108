/* ------------------------------------------------------------ *\
	Video Small
\* ------------------------------------------------------------ */

.video-small {
	position: relative; 
	display: block; 
	padding-top: 56.5%; 
	margin-right: 19%; 

	&:hover {
		.video__btn {
			opacity: .7; 
		}
	}

	.video__image {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		background-position: 50% 50%; 
		background-repeat: no-repeat; 
		background-size: cover;
	}

	.video__btn {
		position: absolute; 
		top: 50%; 
		left: 50%; 
		transform: translate(-50%, -50%); 
		display: inline-block; 
		font-size: 0; 
		line-height: 0; 
		transition: .3s; 
	}	
}