/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widget {
	max-width: 300px; 
	margin-bottom: 51px; 

	@media (max-width: @responsive-xs){
		margin-bottom: 15px; 
	}

	.widget__title {
		margin-bottom: 6px; 
		text-transform: none; 
	}

	.widget__entry {
		line-height: 1.2; 

		p {
			margin-bottom: 20px; 
		}

		strong {
			display: inline-block; 
		}

		a {
			text-decoration: none; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Widget Simple
\* ------------------------------------------------------------ */

.widget-simple {
	margin-bottom: 32px; 

	.widget__head {
		margin-bottom: 42px; 
	}

	.widget__body {
		.list-filters a {
			text-decoration: underline; 
		}
	}
}