/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	display: inline-block;
	vertical-align: middle;
	min-width: 190px; 
	height: 32px; 
	padding: 0 10px; 
	border: 2px solid @color-blue-dark; 
	border-radius: 20px; 
	background: transparent; 
	font-family: @font-family-sans-serif; 
	font-size: 16px; 
	font-weight: 400; 
	line-height: 28px; 
	color: @color-blue-dark;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase; 
	cursor: pointer;
	appearance: none;
	transition: background .3s, color .3s; 

	&:hover {
		background: @color-blue-dark;
		color: #fff;
	}

	&--reversed {
		border-color: #fff;
		color: #fff; 
		font-size: 19px;
		width: 221px; 
		height: 37px;
		margin-right: 68px;
		padding-top: 3px;
		@media (max-width: @responsive-sm) {
			margin-top: 20px;;
		}

		&:hover {
			background: #fff;
			color: @color-blue-dark;
		}
	}

	&--solid {
		display: inline-block; 
		vertical-align: middle; 
		min-width: 150px; 
		height: 38px; 
		border: 2px solid @color-blue; 
		border-radius: 18px; 
		background: @color-blue;
		font-size: 18px; 
		font-weight: 600; 
		line-height: 34px; 
		letter-spacing: .05em; 
		color: #fff;
		text-align: center; 
		text-transform: uppercase; 
		text-decoration: none; 

		&:hover {
			background: transparent; 
			color: #fff;
		}
	}

	&--small {
		min-width: 170px; 
		height: 28px; 
		line-height: 24px; 
	}

	&--xsmall {
		min-width: 138px; 
		height: 24px; 
		border-width: 2px; 
		font-size: 13px; 
		letter-spacing: 0; 
		line-height: 20px; 
		text-transform: none; 
	}

	&--large {
		height: 42px; 
		padding: 0 36px; 
		line-height: 38px; 
		letter-spacing: .05em; 

		@media (max-width: @responsive-xs){
			padding: 0 20px; 
		}
	}

	&--block {
		display: block;
		padding-left: 0;
		padding-right: 0;
	}

	&--bar {
		background: #fff; 
		color: #01042f;
		border: 0;
		min-width: 169px;
		height: 26px;
	}

	&--close-bar {
		padding: 0;
		min-width: 152px;
		border: 0;
		color: #fff; 
		text-transform: uppercase;
		font-weight: 600;
		font-size: 15px;
		letter-spacing: 1px;
		padding: 2px 0 0 8px;
		@media (max-width: @responsive-xxs) {
			margin-top: 15px;
		}
	}
		&--close-bar span { 
			font-size: 22px;
			padding-left: 11px;
		 }
}
