/* ------------------------------------------------------------ *\
	Popup Access
\* ------------------------------------------------------------ */

.popup-access {
	max-width: 575px; 
	margin: 0 auto; 
	background: transparent; 
	font-size: 25px; 
	color: #fff;
	text-align: center; 

	.popup__entry {
		padding: 0 5px; 
	}

	.popup__actions {
		display: flex; 
		justify-content: center; 
		flex-flow: row wrap;

		.btn {
			margin: 0 20px 20px; 
		}
	}
}