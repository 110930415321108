/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

.search {
	position: relative; 
	margin-bottom: 55px; 

	.search__field {
		width: 100%;
		height: 37px; 
		padding: 0 40px 0 20px; 
		border: 2px solid @color-blue-dark;  
		border-radius: 25px; 
		background: transparent; 
		font-family: @font-family-sans-serif; 
		font-size: 16px; 
		font-weight: 400;
		color: @color-blue-dark;
		transition: .3s;  

		&:focus {
			border-color: @color-blue; 
			outline: none;
		}
	}

	.search__btn {
		position: absolute; 
		top: 50%; 
		right: 13px; 
		padding: 0; 
		margin-top: -15px; 
		border: 0 none; 
		border-radius: 0; 
		background: transparent; 
		transition: opacity .3s; 

		&:hover {
			opacity: .7; 
		}
	}

	&--default {
		.search__field {
			border-color: #fff; 
			color: #fff;
		}
	}
}