/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */

.content {
	position: relative; 
	z-index: 1; 
	padding-top: 60px; 
	transition: .3s; 

	&--offset-top-small {
		padding-top: 38px; 
	}
	
	&.active {
		transform: translateX(-8.33333%);  
	}

	@media (max-width: @responsive-xs){
		&.active {
			transform: translateX(0%);  
		}
	}
}
