/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
	ul {
		list-style-type: none; 
		padding: 0; 
		margin: 0; 
	}

	li {
		display: inline-block; 
	}

	li + li {
		margin-left: 27px; 
	}

	a {
		display: flex;
		align-items: center; 
		justify-content: center;  
		vertical-align: middle; 
		width: 36px; 
		height: 36px; 
		border-radius: 50%; 
		background: #fff; 

		&:hover {
			background: @color-blue;

			path {
				fill: #fff;
			}
		}
	}

	&--small {
		margin-top: 6px; 
		font-size: 0; 

		li + li {
			margin-left: 6px; 
		}

		a {
			width: 30px; 
			height: 30px; 
			background: @color-blue-dark; 
		}

		svg[class^='ico-'] {
			width: 18px; 
			height: 16px; 

			path {
				fill: #fff;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Socials Boxes
\* ------------------------------------------------------------ */

.socials-boxes {
	.socials__label {
		letter-spacing: .05em; 
	}

	ul {
		list-style-type: none; 
		display: flex; 
		justify-content: space-between; 
		padding: 0; 
		margin: 0; 
	}

	li {
		width: 100%; 
	}
	li + li {
		margin-left: -1px; 
	}

	a {
		display: flex; 
		justify-content: center; 
		align-items: center; 
		height: 50px; 
		padding: 10px; 
		border: 1px solid #e6e6e6; 
		transition: .3s; 
		
		&:hover {
			background: @color-blue;

			svg[class^='ico-'] {
				path {
					fill: #fff;
				}
			}
		}
	}

	svg[class^='ico-'] {
		width: 10px; 
		height: 10px; 

		path {
			fill: #606163;
		}
	}
}