/* ------------------------------------------------------------ *\
	Callout
\* ------------------------------------------------------------ */

.callout {
	position: relative; 
	background: #fff; 
	color: @color-blue-dark; 

	.callout__inner {
		position: relative; 
		z-index: 1; 
		padding: 40px 0 105px; 
	}

	.callout__head {
		margin-bottom: 35px; 
	}

	.title {
		padding-bottom: 18px; 
		letter-spacing: .06em; 
	}

	hr {
		margin-bottom: 0; 
	}

	&--banner {
		background-position: 50% 50%; 
		background-repeat: no-repeat; 
		background-size: cover;
		color: #fff; 

		&:after {
			content: '';
			position: absolute; 
			top: 0; 
			left: 0; 
			width: 100%; 
			height: 100%; 
			background: fadeout(#006aab, 30%);  
		}

		.callout__inner {
			padding-bottom: 43px; 
		}

		.title {
			&:after {
				background-color: #fff; 
			}
		}
	}
}