/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	margin-bottom: 46px; 
	font-size: 25px; 
	letter-spacing: .05em; 

	ul {
		list-style-type: none; 
		padding: 0; 
		margin: 0; 
	}

	li + li {
		margin-top: 18px; 
	}

	a {
		position: relative; 
		display: block; 
		padding-right: 35px; 
		text-decoration: none; 
		
		&:after {
			content: '';
			position: absolute; 
			top: 1px; 
			right: 0; 
			display: inline-block; 
			width: 32px; 
			height: 32px; 
			background: url(../images/svg/ico-arrow-right.svg) 0 0 no-repeat; 
			background-size: 100% 100%; 
		}
	}

	@media (max-width: @responsive-xs){
		& {
			font-size: 18px; 
		}
		a {
			&:after {
				width: 24px; 
				height: 24px; 
			}
		}
	}
}


/* ------------------------------------------------------------ *\
	Nav Access
\* ------------------------------------------------------------ */

.nav-access {
	font-family: @font-family-gotham; 
	font-size: 13px; 
	letter-spacing: .1em; 
	text-transform: uppercase; 

	ul {
		list-style-type: none; 
		margin-bottom: 0; 
		display: flex; 
		justify-content: space-between; 
	}

	li {
		position: relative; 
	}

	li + li:before {
		content: '';
		position: absolute; 
		bottom: 19px; 
		left: 0; 
		width: 1px; 
		height: 12px; 
		background: #fff; 
	}

	li:first-child a {
		border-radius: 0 0 0 20px; 
		padding-left: 38px; 
	}

	li:last-child a {
		border-radius: 0 0 20px 0; 
		padding-right: 38px; 
	}

	a {
		display: block; 
		padding: 52px 24px 16px; 
		background: @color-blue; 
		color: #fff;
		text-decoration: none; 
		transition: .3s; 

		&:hover {
			background: fadeout(@color-blue, 30%);
			color: #fff;
		}
	}

	&--logged {
		a {
			background: transparent; 

			&:hover {
				background: transparent; 
				color: @color-blue;
			}
		}
	}
}



/* ------------------------------------------------------------ *\
	Nav Access Buttons
\* ------------------------------------------------------------ */

.nav-access-buttons {
	ul {
		list-style-type: none; 
		padding: 0; 
		margin: 0; 
	}

	li {
		display: inline-block; 
		font-size: 0;
	}

	li + li {
		margin-left: 45px; 
	}

	a {
		color: #fff;
		text-decoration: none; 
	}

	&--logged {
		a {
			font-size: 20px; 
			background: transparent; 
			border-color: transparent; 
			
			&:hover {
				color: @color-blue;
			}
		}
	}

	@media (max-width: @responsive-xs){
		& {
			text-align: center; 
		}

		li {
			display: block; 
			margin-bottom: 15px; 
		}

		li + li {
			margin-left: 0; 
		}
	}
}


/* ------------------------------------------------------------ *\
	Nav Secondary
\* ------------------------------------------------------------ */

.nav-secondary {
	ul {
		display: flex; 
		flex-flow: row wrap;
		justify-content: flex-start; 
		align-items: flex-start; 
		list-style-type: none; 
		padding: 0; 
		margin: 0;
		line-height: 1;  
	}

	li {
		position: relative; 
		margin-right: 20px; 

		&:after {
			content: '';
			position: absolute; 
			top: 2px; 
			right: -11px; 
			width: 1px; 
			height: 12px; 
			background: @color-blue-dark;  
		}

		&:last-child {
			margin-right: 0;

			&:after {
				content: none;
			} 
		}

		@media (max-width: @responsive-xs){
			margin-bottom: 10px; 
		}
	}

	li.current {
		a {
			color: @color-blue;
		}
	}

	a {
		color: @color-blue-dark;
		text-decoration: none; 
		transition: .3s; 

		&:hover {
			color: @color-blue;
		}
	}
}