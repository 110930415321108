/* ------------------------------------------------------------ *\
	Tags
\* ------------------------------------------------------------ */

.tags {
	text-transform: uppercase; 

	.tags__head {
		display: inline-block; 
		margin: 0 23px 0 0; 
	}

	.tags__title {
		margin: 0; 
		letter-spacing: .05em; 
	}

	.tags__body {
		display: inline-block; 
	}
}

.tag {
	position: relative; 
	display: inline-block; 
	margin-right: 30px; 
	letter-spacing: .028em;
	text-decoration: none;  

	&:after {
		content: '';
		position: absolute; 
		top: 50%; 
		right: -17px; 
		width: 1px; 
		height: 11px; 
		margin-top: -6px; 
		background: @color-blue-dark;  
	}

	&:last-child {
		margin-right: 0; 

		&:after {
			content: none; 
		}
	}

	&:hover {
		text-decoration: none; 
	}
}