/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
	margin-bottom: 50px;

	 @media (max-width: @responsive-sm){
		margin-bottom: 20px;
	}

	.form__head {
		margin-bottom: 52px;

		@media (max-width: @responsive-xs){
			margin-bottom: 30px;
		}
	}

	.form__row {
		margin-bottom: 49px;

		&:last-child {
			margin-bottom: 0;
		}

		@media (max-width: @responsive-sm){
			margin-bottom: 30px;
		}

		@media (max-width: @responsive-xs){
			margin-bottom: 20px;
		}
	}

	.form__body {
		margin-bottom: 29px;
	}

	.form__label {
		display: block;
		margin-bottom: 13px;
		font-size: 20px;
		font-weight: 700;
	}

	.form__actions {
		.btn {
			min-width: 180px;
			font-weight: 700;
		}
	}

	.form__collection {
		padding: 10px 0 30px;
	}

	.form__entry-title {
		margin-bottom: 30px;
	}
}


/* ------------------------------------------------------------ *\
	Form Access
\* ------------------------------------------------------------ */

.form-access {
	.form__row {
		margin-bottom: 20px;
	}

	.form__head {
		margin-bottom: 22px;
	}

	.form__title {
		margin: 4px 0;
		font-size: 15px;
		font-weight: 800;
		letter-spacing: .05em;
	}

	.form__body {
		margin-bottom: 27px;
	}

	.form__controls {
		text-align: right;
	}

	.form__link {
		font-size: 14px;
		letter-spacing: .05em;
		text-decoration: underline;

		&:hover {
			color: @color-blue-dark
		}
	}

	.form__actions {
		text-align: right;
	}
}


/* ------------------------------------------------------------ *\
	Form Callout
\* ------------------------------------------------------------ */

.form-callout {
	.form__row {
		margin-bottom: 20px;
	}

	.form__body {
		margin-bottom: 19px;
	}

	.form__actions {
		text-align: right;
		@media (max-width: @responsive-xs){
			text-align: center;
		}
	}
}

/* ------------------------------------------------------------ *\
	Form Trial
\* ------------------------------------------------------------ */

.form-trial {
	.form__inner {
		display: flex;
		flex-flow: column wrap;
	}

	.form__head {
		padding-bottom: 1px;
		color: #fff;
		letter-spacing: 1px;
	}

	.form__body {
		 padding-top: 9px;
	 }

	.form__row {
		margin: 0 -15px 10px -15px;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		@media (max-width: @responsive-xs) {
			margin: 0 -15px;
		}
	}

	.form__row + .form__row {
		margin: 10px -15px 2px -15px;
		@media (max-width: @responsive-xs) {
			margin: 0 -15px;
		}
	}

	.form__controls {
		width: 50%;
		padding: 0 15px;
		@media (max-width: @responsive-xs) {
			width: 100%;
			padding: 15px;
		}
	}

	.form__actions {
		width: 50%;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		padding: 3px 0 0 13px;
		align-items: center;
		@media (max-width: @responsive-xs) {
			width: 100%;
			justify-content: center;
			padding: 10px 0;
			@media (max-width: @responsive-xxs) {
				flex-flow: column wrap;
				padding: 10px 15px 0;
			}
		}
	}

	.field { text-transform: none; border: none; }
}
