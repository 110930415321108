/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
	position: relative; 
	overflow: hidden; 
	display: flex; 
	align-items: center; 
	min-height: 530px; 
	padding: 30px 0 160px; 
	background-color: @color-blue-dark;
	background-position: 50% 50%; 
	background-repeat: no-repeat; 
	background-size: cover;
	color: #fff;

	@media (max-width: @responsive-md){
		min-height: 530px; 
	}

	@media (max-width: @responsive-xs){
		min-height: 530px; 
		padding: 30px 0 60px; 
	}

	.intro__content {
		position: absolute; 
		top: 0; 
		left: -99999px; 
		overflow: hidden; 
		opacity: 0; 
		visibility: hidden; 
		transition: opacity .3s, visibility .3s; 

		&.current {
			position: relative; 
			top: 0; 
			left: 0; 
			opacity: 1; 
			visibility: visible; 
		}
	}

	.intro__title {
		position: relative; 
		margin-bottom: 36px; 

		&:after {
			content: '';
			position: absolute; 
			bottom: -17px; 
			left: 0; 
			width: 70px; 
			height: 8px; 
			background: #fff;  
		}
	}

	.intro__entry {
		margin-bottom: 42px; 
		font-size: 25px; 
		line-height: 1.2; 
		letter-spacing: .025em; 

		@media (max-width: @responsive-xs){
			margin-bottom: 30px; 
			font-size: 20px; 
		}
	}

	.intro__actions {
		.btn + .btn {
			margin-left: 15px; 
		}

		@media (max-width: @responsive-xs){
			.btn {
				display: block; 
				margin-bottom: 15px; 
			}

			.btn + .btn {
				margin-left: 0; 
			}
		}
	}

	/*  Intro Nav  */
	.intro__nav {
		position: absolute; 
		bottom: 0; 
		left: 0; 
		width: 100%; 
		font-size: 15px; 
		font-weight: 700; 
		letter-spacing: .05em; 
		text-transform: uppercase; 
		text-align: center; 

		@media (max-width: @responsive-xs){
			font-size: 0; 
		}

		ul {
			position: relative; 
			list-style-type: none; 
			display: flex; 
			justify-content: space-between; 
			padding: 0; 
			margin: 0; 

			&:before,
			&:after {
				content: '';
				position: absolute; 
				top: 0; 
				bottom: 0; 
				width: 9999px; 
				background: fadeout(@color-blue, 25%); 
			}

			&:before {
				right: 100%; 
			}

			&:after {
				left: 100%; 
			}
		}

		li {
			position: relative; 
			flex: 0 0 25%;
			display: flex; 
			max-width: 25%; 

			&:before {
				content: '';
				position: absolute; 
				top: 80px; 
				left: 0; 
				z-index: 1; 
				width: 1px; 
				height: 30px; 
				background: #fff; 
				pointer-events: none;
			}

			&:last-child:after{
				content: '';
				position: absolute; 
				top: 80px; 
				right: -1px; 
				z-index: 1; 
				width: 1px; 
				height: 30px; 
				background: #fff; 
				pointer-events: none;
			}
			
			&:hover a,
			&.current a {
				background: rgba(255, 255, 255, .75); 
				color: @color-blue-dark;

				&:after {
					opacity: 1; 
				}
			}
			
			&:hover svg path,
			&.current svg path {
				fill: @color-blue-dark;
			}
		}

		a {
			position: relative; 
			display: block; 
			width: 100%; 
			padding: 24px 10px 25px 10px; 
			background: fadeout(@color-blue, 25%); 
			color: #fff;
			text-decoration: none; 
			transition: .3s; 

			&:after {
				content: '';
				position: absolute; 
				bottom: 100%; 
				left: 50%; 
				transform: translateX(-50%); 
				width: 0; 
				height: 0; 
				border-width: 0 34px 16px 34px; 
				border-style: solid; 
				border-color: transparent transparent rgba(255, 255, 255, .75) transparent; 
				opacity: 0; 
				transition: .3s; 
			}
			
			@media (max-width: @responsive-md){
				& {
					padding: 20px 10px; 
				}
			}

			@media (max-width: @responsive-xs){
				& {
					padding: 10px 5px; 
				}

				&:after {
					border-width: 0 18px 10px 18px; 
				}
			}
		}

		.intro__nav-icon {
			margin-bottom: 7px; 
		}
	}

	&--height-small {
		min-height: 464px; 

		@media (max-width: @responsive-xs){
			padding: 100px 0; 
		}
	}
}
