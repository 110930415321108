/* ------------------------------------------------------------ *\
	Title
\* ------------------------------------------------------------ */

.title {
	position: relative;
	margin: 0 0 10px;  
	letter-spacing: .05em; 

	&:after {
		content: '';
		position: absolute; 
		bottom: -10px; 
		left: 0; 
		width: 70px; 
		height: 1px; 
		background: @color-blue-dark;  
		transition: background .3s; 
	}

	&--narrow {
		letter-spacing: 0; 
	}

	&--plus {
		padding-right: 22px; 

		.title__inner {
			&:before,
			&:after {
				content: '';
				position: absolute; 
				top: 0; 
				right: 8px; 
				width: 3px; 
				height: 20px; 
				background: @color-blue-dark; 
				pointer-events: none;
				transition: .3s; 
			}

			&:after {
				transform: rotate(0deg); 
			}
		
			&:after {
				transform: rotate(-90deg); 
			}
		}
	}

	&--underline {
		padding-bottom: 17px; 
		margin-bottom: 40px; 

		&:after {
			width: 100%; 
		}
	}

	&--alternative {
		margin-bottom: 18px; 
		font-weight: 400; 
		letter-spacing: .025em; 
		text-transform: none; 

		&:after {

			width: 35px;
		}
	}
}


/* ------------------------------------------------------------ *\
	Title Arrow
\* ------------------------------------------------------------ */

.title-arrow {
	position: relative; 
	padding: 0 40px 12px 0; 
	margin: 0; 
	letter-spacing: 0; 
	text-transform: none; 

	&:before {
		content: '';
		position: absolute; 
		bottom: 0; 
		left: 0; 
		width: 100%; 
		height: 1px; 
		background: @color-blue-dark;  
	}

	&:after {
		content: ''; 
		position: absolute; 
		top: 6px; 
		right: 0; 
		width: 24px; 
		height: 16px; 
		background: url(../images/svg/ico-arrow-down.svg) 0 0 no-repeat; 
		transition: .3s; 
	}
}