/* ------------------------------------------------------------ *\
	Accordion
\* ------------------------------------------------------------ */

.accordion {
	& > .accordion__section {
		& > .accordion__head {
			position: relative; 
			padding-right: 30px; 
			margin-bottom: 37px; 
			cursor: pointer; 
			
			&:before,
			&:after {
				content: '';
				position: absolute; 
				top: 0; 
				right: 8px; 
				width: 3px; 
				height: 20px; 
				background: #fff; 
				pointer-events: none;
				transition: .3s; 
			}

			&:after {
				transform: rotate(0deg); 
			}
		
			&:after {
				transform: rotate(-90deg); 
			}
		}

		& > .accordion__body {
			display: none; 
			padding: 11px 0 35px; 
		}

		&.opened {
			& > .accordion__head {
				&:before {
					transform: rotate(-45deg); 
				}
			
				&:after {
					transform: rotate(-135deg); 
				}
			}
		}

		.accordion__selected {
			margin-bottom: 10px; 
		}
	}

	&--default {
		& > .accordion__section {
			& > .accordion__head {
				&:before,
				&:after {
					background: @color-blue-dark; 
				}
			}
		}
	}
}

.accordion-arrow {
	& > .accordion__section {
		max-width: 180px; 
		
		& > .accordion__head {
			position: relative; 
			padding-right: 15px;  
			margin-bottom: 7px; 
			cursor: pointer; 
			transition: .3s; 

			&:after {
				content: '';
				position: absolute; 
				top: 0; 
				right: 2px; 
				transform: rotate(45deg); 
				width: 11px; 
				height: 11px; 
				border-width: 0 3px 3px 0; 
				border-style: solid; 
				border-color: #fff; 
				margin-top: 2px; 
				transition: .3s; 
			}

			&:hover {
				color: @color-blue;
			}
		}

		& > .accordion__body {
			display: none;
			padding: 5px 10px 25px; 
		}

		&.opened {
			& > .accordion__head {
				&:after {
					margin-top: 5px; 
					transform: rotate(-135deg); 
				}
			}
		}
	}

	&--default {
		& > .accordion__section {
			& > .accordion__head {
				&:after {
					border-color: @color-blue-dark; 
				}
			}
		}
	}
}