/* ------------------------------------------------------------ *\
	Features Simple
\* ------------------------------------------------------------ */

.features-simple {
	display: flex; 
	flex-flow: row wrap;
	justify-content: space-between; 
	align-items: stretch;
	margin: 0 -15px 35px; 

	@media (max-width: @responsive-sm){
		margin-bottom: 10px; 
	}
}

/* ------------------------------------------------------------ *\
	Feature Simple
\* ------------------------------------------------------------ */

.feature-simple {
	flex: 0 0 33.33%;
	max-width: 33.33%; 
	display: flex; 
	justify-content: flex-start; 
	padding: 0 15px; 
	margin-bottom: 60px; 

	@media (max-width: @responsive-xs){
		flex: 0 0 100%; 
		max-width: 100%; 
		margin-bottom: 30px; 
	}

	.feature__icon {
		flex-shrink: 0;
		width: 58px; 
		height: 58px; 
		margin-right: 15px; 
	}

	.feature__title {
		margin: 0; 
		line-height: 1.2; 
		letter-spacing: 0; 
	}

	.feature__entry {
		line-height: 1.13; 
	}
}