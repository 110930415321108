/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */

.sidebar {
	padding-top: 50px; 

	@media (max-width: @responsive-xs){
		position: absolute; 
		top: 0; 
		left: 0; 
		bottom: 0; 
		width: 100%; 
	}

	.sidebar__head {
		position: relative; 
		transition: .5s; 

		@media (max-width: @responsive-sm){
			& > .title {
				display: none; 
			}
		}
	}

	.sidebar__body {
		position: absolute; 
		top: 162px; 
		bottom: 0; 
		left: calc(~"75% + 15px");
		right: 15px; 
		transform: translateX(100%); 
		padding: 33px 0 20px 35px; 
		margin-top: -22px; 
		background: @color-blue-dark; 
		color: #fff;
		opacity: 0; 
		visibility: hidden; 
		transition: .5s; 

		&:after {
			content: ''; 
			position: absolute; 
			top: 0; 
			left: 100%; 
			width: 9999px; 
			height: 100%; 
			background: @color-blue-dark; 
		}
		
		@media (max-width: @responsive-md){
			left: 70%;
		}

		@media (max-width: @responsive-sm){
			left: 65%;
		}

		@media (max-width: @responsive-xs){
			position: relative; 
			top: 17px; 
			left: 0;
			z-index: 5; 
			height: 100%; 
			padding: 30px; 
		}
	}

	.sidebar__body-inner {
		position: relative; 
		overflow-y: auto; 
		height: 100%; 

		&::-webkit-scrollbar { 
			display: none; 
		}
	}

	.nav-trigger {
		position: absolute; 
		top: 50%; 
		right: 0; 
		z-index: 5; 
		transform: translateY(-50%); 
		transition: margin .5s, transform .5s; 

		&.active {
			margin-right: 100%;  
			transform: translate(-20px, -50%); 
		}

		@media (max-width: @responsive-md){
			top: -10px; 
			transform: translateY(-100%); 

			&.active {
				margin-right: 100%;  
				transform: translate(-20px, -100%); 
			}
		}

		@media (max-width: @responsive-xs){
			top: -9px; 
			right: 15px; 

			&.active {
				transform: translate(60px, -100%); 
			}
		}
	}

	.title {
		margin: 0; 
		letter-spacing: 0; 
		transition: .3s .2s;
	}

	.search {
		transition: .3s; 

		@media (max-width: @responsive-xs){
			display: none; 
		}
	}

	.poster {
		transition: .3s; 
	}

	&--with-poster {
		.sidebar__body {
			top: 525px; 
		}

		@media (max-width: @responsive-xs){
			.sidebar__body {
				top: 17px; 
			}
		}
	}

	&--offset-top-small {
		padding-top: 38px; 

		@media (max-width: @responsive-xs){
			padding-top: 44px; 
		}
	}

	&.active {
		.search {
			transform: translateX(-27%); 
		}

		.ad {
			transform: translateX(-27%); 
		}

		.title {
			transition: .3s; 

			&:after {
				background: #fff;
			}
		}

		.sidebar__head {
			.title {
				opacity: 0; 
				visibility: hidden; 
			}
		}

		.sidebar__body {
			transform: translateX(0);
			opacity: 1; 
			visibility: visible;
		}
	}
}


/* ------------------------------------------------------------ *\
	Sidebar Simple
\* ------------------------------------------------------------ */

.sidebar-simple {
	padding: 60px 0 40px; 

	@media (max-width: @responsive-xs){
		position: absolute; 
		top: 0; 
		left: 0; 
		z-index: 1; 
		width: 100%; 
		height: 100%; 
	}
	
	.sidebar__inner {
		position: absolute; 
		top: 150px; 
		right: 15px;
		bottom: 0; 
		width: calc(~"33.3333333% - 30px"); 
		padding-bottom: 40px; 

		&--offset-top-small {
			top: 70px; 
		}

		@media (max-width: @responsive-xs){
			top: 60px; 
			right: 0; 
			width: 100%; 
			padding: 0 20px 30px; 
		}
	}

	.sidebar__body {
		height: 100%; 
		overflow-y: auto; 
		overflow-x: hidden; 

		@media (max-width: @responsive-xs){
			position: absolute; 
			top: 0; 
			left: 0; 
			transform: translateX(100%); 
			width: 100%; 
			height: 100%; 
			background: #fff; 
			padding: 20px; 
			transition: .3s; 
		}
	}

	.nav-trigger {
		position: absolute; 
		top: 15px; 
		right: 15px; 
	}

	.search {
		margin-bottom: 38px; 
	}

	&.active {
		.sidebar__body {
			@media (max-width: @responsive-xs){
				transform: translateX(0); 
			}
		}
	}
}