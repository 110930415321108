/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

[class^="list-"] {
	list-style: none outside none;
}

/* ------------------------------------------------------------ *\
	List Links
\* ------------------------------------------------------------ */

.list-links {
	list-style-type: none; 
	padding: 0; 
	margin: 0; 
	font-size: 14px; 
	letter-spacing: .075em; 

	& + & {
		margin-left: 45px; 
	}

	li + li {
		margin-top: 15px; 
	}

	a {
		text-decoration: none; 
	}
	
	&--text-big {
		font-size: 16px; 

		li + li {
			margin-top: 12px; 
		}
	}
}

/* ------------------------------------------------------------ *\
	List Countries
\* ------------------------------------------------------------ */

.list-countries {
	padding: 0; 
	margin: 0; 
	columns: 4;
	column-gap: 25px;
	font-weight: 700; 

	ul {
		list-style-type: none; 
		padding: 0 0 0 9px; 
		margin: 0; 
		font-weight: 400; 
	}

	li + li {
		margin-top: 1px; 
	}

	a {
		&:focus,
		&:hover {
			text-decoration: none;
		}
	}

	@media (max-width: @responsive-sm) {
		columns: 3;
		column-gap: 15px;
	}

	@media (max-width: @responsive-xs) {
		columns: 2;
		column-gap: 15px;
	}
}

/* ------------------------------------------------------------ *\
	List Dots
\* ------------------------------------------------------------ */

.list-dots {
	list-style-type: none; 
	padding: 0; 
	margin: 0; 

	li {
		position: relative; 
		padding-left: 15px; 

		&:before {
			content: '';
			position: absolute; 
			top: 9px; 
			left: 1px; 
			width: 5px; 
			height: 5px; 
			border-radius: 50%; 
			background: @color-blue-dark; 
		}
	}

	li + li {
		margin-top: 27px; 
	}
}

/* ------------------------------------------------------------ *\
	List Filters
\* ------------------------------------------------------------ */

.list-filters {
	padding: 0; 
	margin: 0; 

	li + li {
		margin-top: 7px; 
	}

	li.current {
		display: none; 
	}

	a {
		&:focus,
		&:hover {
			text-decoration: none;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Contacts
\* ------------------------------------------------------------ */

.list-contacts {
	list-style-type: none; 
	padding: 0; 
	margin: 0; 

	li + li {
		margin-top: 2px; 
	}

	.list__icon {
		display: inline-block;
		vertical-align: middle; 
		margin-right: 8px;  
	}

	a {
		text-decoration: none; 
		
		&:hover {
			color: @color-blue;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Dashes
\* ------------------------------------------------------------ */

.list-dashes {
	list-style-type: none; 
	padding: 0; 
	margin: 9px 0 0; 
	font-size: 18px; 
	font-weight: 700; 
	
	li {
		position: relative; 
		padding-left: 10px; 

		&:before {
			content: '';
			position: absolute; 
			top: 14px; 
			left: 0; 
			width: 4px; 
			height: 2px; 
			background: @color-blue-dark; 
		}
	}

	li + li {
		margin-top: 2px; 
	}

	a {
		&:hover {
			text-decoration: none;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Dashes Default
\* ------------------------------------------------------------ */

.list-dashes-default {
	list-style-type: none; 
	padding: 0; 
	margin: 0; 

	li {
		position: relative; 
		padding-left: 6px; 

		&:before {
			content: '';
			position: absolute; 
			top: 10px; 
			left: 0; 
			width: 4px; 
			height: 2px; 
			background: @color-blue-dark; 
		}
	}
}


/* ------------------------------------------------------------ *\
	List Members
\* ------------------------------------------------------------ */

.list-members {
	list-style-type: none; 
	padding: 0; 
	margin: 0; 
	line-height: 1.35; 

	li + li {
		margin-top: 22px; 
	}
}