/* ------------------------------------------------------------ *\
	Bar
\* ------------------------------------------------------------ */

.bar {
	display: block;
	background-color: @color-aqua;

	&:hover {
		text-decoration: none;
	}

	.bar__header {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
		padding: 21px 0 22px;
		color: #fff;
		@media (max-width: @responsive-sm) {
			justify-content: center;
		} 

		@media (max-width: @responsive-xs) {
			justify-content: flex-start;
		}

		ul {
			display: flex;
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;
			font-size: 21px;
			font-weight: 800;

			li {
				padding: 0 25px;
				border-left: 1px solid #fff;

				@media (max-width: @responsive-sm) {
					padding: 0 10px;
					border: 0;
				}

				@media (max-width: @responsive-xs) {
					padding: 0;
				}
			}

			li + li {
				@media (max-width: @responsive-xs) {
					margin-left: 20px;
				}
			}
		}

		.btn {
			display: block;
			opacity: 1;
			visibility: visible;
			transition: opacity .3s, visibility .3s;

			&.open {
				pointer-events: none;
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	.bar__title { 
		text-transform: none;
		font-weight: 400;
		letter-spacing: 0px;
		margin: 0;
		font-weight: 800;
		font-size: 21px;
		@media (max-width: @responsive-xs) {
			font-size: 20px;
		}
	 }

	.bar__header span { 
		color: #01042f; 
		font-weight: 400;
	 }

	.bar__body { 
		padding: 83px 0 42px;
		margin-top: -60px;
		will-change: padding, height;
		display: none;
	  }
}
