/* ------------------------------------------------------------ *\
	Nav Trigger
\* ------------------------------------------------------------ */

.nav-trigger { 
	position: relative; 
	z-index: 20; 
	display: inline-block; 
	width: 44px; 
	height: 30px; 
	transition: opacity .3s; 

	span { 
		position: absolute; 
		display: block; 
		right: 3px; 
		height: 6px; 
		width: 100%; 
		background: #fff; 
		transition: top .2s .25s, left .2s .25s, background .3s, opacity .2s .25s, -webkit-transform .2s 0s; 
		transition: top .2s .25s, left .2s .25s, background .3s, opacity .2s .25s, transform .2s 0s;
		transition: top .2s .25s, left .2s .25s, background .3s, opacity .2s .25s, transform .2s 0s, -webkit-transform .2s 0s;

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2) {
			top: 12px; 
		}

		&:nth-child(3) {
			top: 24px; 
		}
	}

	.nav-trigger__text {
		position: absolute; 
		top: 100%; 
		right: 0; 
		width: auto; 
		height: auto; 
		padding-top: 8px; 
		font-size: 15px; 
		font-weight: 800; 
		color: #fff;
		letter-spacing: .05em; 
		white-space: nowrap; 
		text-transform: uppercase; 
		pointer-events: none;
		transition: opacity .3s; 
	}

	.nav-trigger__text + .nav-trigger__text {
		opacity: 0; 
	}
	
	&:focus {
		color: inherit;
	}

	&:hover {
		span {
			background: @color-blue;
		}
	}

	&.active {
		span {
			transition: background .2s, top .2s, left .2s, opacity .2s, -webkit-transform .2s .25s;
			transition: background .2s, top .2s, left .2s, opacity .2s, transform .2s .25s; 
			transition: background .2s, top .2s, left .2s, opacity .2s, transform .2s .25s, -webkit-transform .2s .25s;

			&:nth-child(1),
			&:nth-child(3) {
				top: 12px;
			}

			&:nth-child(2) {
				opacity: 0;  
			}

			&:nth-child(1) {
				-webkit-transform: rotate(45deg); 
				-ms-transform: rotate(45deg); 
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				-webkit-transform: rotate(-45deg); 
				-ms-transform: rotate(-45deg); 
				transform: rotate(-45deg);
			}
		}

		.nav-trigger__text {
			opacity: 0; 
			transition: opacity .3s .2s; 
		}

		.nav-trigger__text + .nav-trigger__text {
			opacity: 1; 
		}
	}

	&--dark {
		span {
			background: @color-blue-dark; 
		}
	}
}