/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

input::placeholder {
	color: inherit;
	opacity: 1;
}

textarea::placeholder {
	color: inherit;
	opacity: 1;
}

input:-webkit-autofill {
	-webkit-text-fill-color: inherit;
	-webkit-box-shadow: 0 0 0 1000px #fff inset;
}

.textarea,
.field {
	width: 100%;
	border: 3px solid @color-blue-dark;
	border-radius: 0;
	outline: none;
	background: #fff;
	font-size: 14px;
	letter-spacing: .05em;
	color: #929292;
	text-transform: uppercase;
	appearance: none;
	transition: .3s;

	&:focus {
		border-color: @color-blue;
		color: @color-blue;
	}

	&--default {
		border: 0 none;
	}

	&--large {
		font-size: 18px;
		border-width: 2px;
	}
}

.field {
	height: 46px;
	padding: 0 13px;

	&--large {
		height: 56px;
	}
}

.textarea {
	resize: none;
	height: 200px;
	padding: 13px;

	&--large {
		height: 312px;
	}
}

.checkbox {
	input {
		position: absolute;
		opacity: 0;
	}

	label {
		position: relative;
		padding-left: 50px;
		line-height: 1.6;

		&:before {
			content: '';
			position: absolute;
			top: 2px;
			left: 0;
			width: 20px;
			height: 20px;
			border-radius: 3px;
			border: 1px solid #000;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: 15px 12px;
		}

		@media (max-width: @responsive-xs) {
			padding-left: 40px;
		}
	}

	input:checked + label:before {
		background-image: url(../images/svg/check-solid.svg);
	}

	+.checkbox {
		margin-top: 10px;
	}
}
